import { DateTime, IANAZone, Settings as luxonSettings } from 'luxon';

export const INT_MAX_VALUE = 2147483647;

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
export const passwordOptions = {
	minLength: 6,
	maxLength: 100,
	requireNonAlphanumeric: true,
	requireDigit: true,
	requireLowercase: true,
	requireUppercase: true,
};

export const validation = {
	required: (value) => { return value !== null && typeof value === 'string' && value.trim().length > 0; },
	email: (value) => { return !validation.required(value) || emailRegex.test(value); },
	password: {
		minLength(value) { return value.length >= passwordOptions.minLength; },
		maxLength(value) { return value.length <= passwordOptions.maxLength; },
		requireNonAlphanumeric(value) { return !passwordOptions.requireNonAlphanumeric || /[^a-zA-Z0-9]+/.test(value); },
		requireDigit(value) { return !passwordOptions.requireDigit || /[0-9]+/.test(value); },
		requireLowercase(value) { return !passwordOptions.requireLowercase || /[a-z]+/.test(value); },
		requireUppercase(value) { return !passwordOptions.requireUppercase || /[A-Z]+/.test(value); },
		all(value) {
			return !validation.required(value) || (
				this.minLength(value) &&
				this.maxLength(value) &&
				this.requireNonAlphanumeric(value) &&
				this.requireDigit(value) &&
				this.requireLowercase(value) &&
				this.requireUppercase(value)
			);
		}
	},
};

export function url(path, query) {
	let url = new URL(path, window.location);
	for (const key in query) {
		if (Object.hasOwnProperty.call(query, key)) {
			url.searchParams.set(key, query[key]);
		}
	}
	return url.toString();
}

export function autoExpandTextarea(textarea) {
	if (!textarea || !(textarea instanceof Element)) { return; }
	if (!textarea.value) {
		textarea.style.height = '';
		textarea.rows = 0;
		return;
	}
	let currentHeight = textarea.getBoundingClientRect().height;
	if (currentHeight > 0) {
		let offset = currentHeight - textarea.clientHeight;
		let neededHeight = textarea.scrollHeight + offset;
		if (neededHeight != currentHeight) {
			textarea.style.height = textarea.scrollHeight + offset + 'px';
			textarea.rows = 0;
		}
	} else {
		let lines = textarea.value.split('\n').length;
		if (lines != textarea.rows) {
			textarea.style.height = '';
			textarea.rows = lines;
		}
	}
}

export function autoExpandTextareaListener(e) {
	autoExpandTextarea(e.target);
}

export const autoExpandTextareaListeners = {
	'change': autoExpandTextareaListener,
	'input': autoExpandTextareaListener,
	'paste': autoExpandTextareaListener
}

export function inputDateToDateTime(dateString, timeString) {
	if (typeof dateString === 'string' && dateString.length > 0) {
		if (typeof timeString === 'string' && timeString.length > 0) {
			return DateTime.fromISO(dateString + 'T' + timeString);
		} else {
			return DateTime.fromISO(dateString);
		}
	}
	return null;
}

export function inputTimeToDateTime(dateTime, timeString) {
	if (!(dateTime && dateTime instanceof DateTime && dateTime.isValid)) {
		dateTime = DateTime.now().startOf('day');
	}
	if (typeof timeString === 'string' && timeString.length > 0) {
		const newDateTime = DateTime.fromISO(dateTime.toISODate() + 'T' + timeString).startOf('minute');
		if (newDateTime.isValid) {
			dateTime = newDateTime;
		}
	}
	return dateTime;
}

export function dateTimeToInputDate(input) {
	if (input && input instanceof DateTime) {
		return input.toISODate();
	}
	return '';
}

export function dateTimeToInputTime(input) {
	if (input && input instanceof DateTime) {
		return input.toISOTime({ suppressMilliseconds: true, suppressSeconds: true, includeOffset: false });
	}
	return '';
}

export async function appendScriptToHead(id, url) {
	if (document.getElementById(id)) { return Promise.resolve(); }
	return new Promise((resolve, reject) => {
		const script = document.createElement("script");
		script.id = id;
		script.onabort = reject;
		script.onerror = reject;
		script.onload = resolve;
		document.head.appendChild(script);
		script.src = url;
	});
}

export function nullableInt(input) {
	let output = null;
	if (typeof input === 'string' && input.length > 0) {
		output = parseInt(input);
	}
	if (isNaN(output)) {
		output = null;
	}
	return output;
}

export function nullToEmpty(input) {
	return input === null ? '' : input;
}

export function getMapUrlSearchAddress(lead) {
	let address = lead.street + ', ' + lead.city + ', ' + lead.state + ' ' + lead.zipCode;
	return 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(address);
}

export function getMapUrlDirectionsAddress(lead) {
	let address = lead.street + ', ' + lead.city + ', ' + lead.state + ' ' + lead.zipCode;
	return 'https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=' + encodeURIComponent(address);
}

export function formatPercent(amount, preventTransform = false) {
	return preventTransform ? `${amount} %` : amount.toLocaleString(undefined, { style: 'percent' });
}

export function formatCurrency(amount) {
	return amount.toLocaleString(undefined, { style: 'currency', currency: 'USD' });
}

const cleanPhoneFormat = /^\+1\d{10}$/;
const prettyPhoneFormat = /^\(\d{3}\) \d{3}-\d{4}$/;

export function cleanPhone(input) {
	if (!input) {
		input = '';
	}
	if (cleanPhoneFormat.test(input)) {
		return input;
	}
	let cleaned = input.replace(/[^\d]/g, '');
	// +12625749400
	if (cleaned.length == 10) {
		return '+1' + cleaned;
	} else if (cleaned.length == 11) {
		return '+' + cleaned;
	} else {
		return input;
	}
}

export function formatPhone(input) {
	if (prettyPhoneFormat.test(input)) {
		return input;
	}
	input = cleanPhone(input);
	if (cleanPhoneFormat.test(input)) {
		return '(' + input.substr(2, 3) + ') ' + input.substr(5, 3) + '-' + input.substr(8);
	} else {
		return input;
	}
}

export function roundToStep(value, field) {
	const { step } = field;
	if (step === 0.333) {
		value = Math.round(value / (1 / 3)) * (1 / 3);
	} else {
		value = Math.round(value / step) * step;
	}

	return value;
}

export function round(number, precision) {
	const x = parseInt('1' + ('0'.repeat(Math.max(0, precision))));
	return Math.round(number * x) / x;
}

export function roundUpToStepAndClamp(value, field) {
	const { min, max, step } = field;
	value = Math.max(0, value - min);
	if (step === 0.333) {
		const whole = Math.floor(value);
		const part = Math.ceil((value - whole) * 3) / 3;
		value = whole + part;
	} else {
		value = Math.ceil(value / step) * step;
	}
	return Math.min(max, round(value + min, 3));
}

export function copyValues(target, source) {
	for (const key in source) {
		if (Object.hasOwnProperty.call(source, key) && Object.hasOwnProperty.call(target, key)) {
			target[key] = source[key];
		}
	}
	return target;
}

export function isImageFile(file) {
	return ['image/jpeg', 'image/png', 'image/gif'].includes(file.type);
}

export async function resizeImage(file) {
	return new Promise((resolve, reject) => {
		if (!isImageFile(file)) { reject(new Error('Invalid image')); }

		const img = document.createElement('img');
		const reader = new FileReader();
		reader.onload = function (e) {
			img.onload = async function () {
				let width = img.width;
				let height = img.height;
				let MAX_WIDTH = 1920;
				let MAX_HEIGHT = 1080;
				if (height > width) {
					const tmp = MAX_HEIGHT;
					MAX_HEIGHT = MAX_WIDTH;
					MAX_WIDTH = tmp;
				}
				if (width > MAX_WIDTH || height > MAX_HEIGHT) {
					const wM = width / MAX_WIDTH;
					const hM = height / MAX_HEIGHT;
					if (wM > hM) {
						width = MAX_WIDTH;
						height = Math.floor(height / wM);
					} else {
						width = Math.floor(width / hM);
						height = MAX_HEIGHT;
					}
				}

				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				canvas.width = width;
				canvas.height = height;
				ctx.drawImage(img, 0, 0, width, height);

				const blob = await canvasToBlobAsync(canvas, file.type, 0.8)
				const newFile = new File([blob], file.name, { type: file.type, lastModified: file.lastModified });
				if (newFile.size < file.size) {
					resolve(newFile);
				} else {
					resolve(file);
				}
			};
			img.onerror = function (e) { reject(e); };
			img.src = e.target.result;
		};
		reader.onerror = function (e) { reject(e); };
		reader.readAsDataURL(file);
	});
}

export function canvasToBlobAsync(canvas, type, quality) {
	return new Promise((resolve, reject) => {
		try {
			canvas.toBlob(blob => {
				resolve(blob);
			}, type, quality);
		} catch (e) {
			reject(e);
		}
	});
}

export async function previewImage(file) {
	if (!isImageFile(file)) { throw new Error('Invalid image'); }
	return await getDataUrlFromBlob(file);
}

export function getDataUrlFromBlob(blob) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = function (e) {
			resolve(e.target.result);
		};
		reader.onerror = function (e) { reject(e); };
		reader.readAsDataURL(blob);
	});
}

export function sentenceJoin(array) {
	if (array.length === 0) {
		return '';
	} else if (array.length === 1) {
		return array[0];
	} else {
		return array.slice(0, -1).join(', ') + ' and ' + array[array.length - 1];
	}
}

export function createCallbacks() {
	const callbacks = [];
	function callCallbacks(...args) {
		for (let i = 0; i < callbacks.length; i++) {
			callbacks[i].apply(undefined, args);
		}
	}
	callCallbacks.apply()
	function addCallback(callback) {
		if (typeof callback === 'function') {
			callbacks.push(callback);
		}
	}
	return { callCallbacks, addCallback };
}

export function escapeRegex(string) {
	return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function getRandomColor() {
	let r = Math.floor(Math.random() * 256).toString(16);
	r = r.length === 1 ? '0' + r : r;
	let g = Math.floor(Math.random() * 256).toString(16);
	g = g.length === 1 ? '0' + g : g;
	let b = Math.floor(Math.random() * 256).toString(16);
	b = b.length === 1 ? '0' + b : b;
	return '#' + r + g + b;
}

function convertHexColorToHSL(hex) {
	if (!(typeof hex === 'string' && /^#(?:[0-9A-F]{6}|[0-9A-F]{3})$/i)) {
		hex = "#000";
	}
	// Convert hex to RGB first
	let r = 0, g = 0, b = 0;
	if (hex.length == 4) {
		r = "0x" + hex[1] + hex[1];
		g = "0x" + hex[2] + hex[2];
		b = "0x" + hex[3] + hex[3];
	} else if (hex.length == 7) {
		r = "0x" + hex[1] + hex[2];
		g = "0x" + hex[3] + hex[4];
		b = "0x" + hex[5] + hex[6];
	}
	// Then to HSL
	r /= 255;
	g /= 255;
	b /= 255;
	let cmin = Math.min(r, g, b),
		cmax = Math.max(r, g, b),
		delta = cmax - cmin,
		h = 0,
		s = 0,
		l = 0;

	if (delta == 0)
		h = 0;
	else if (cmax == r)
		h = ((g - b) / delta) % 6;
	else if (cmax == g)
		h = (b - r) / delta + 2;
	else
		h = (r - g) / delta + 4;

	h = Math.round(h * 60);

	if (h < 0)
		h += 360;

	l = (cmax + cmin) / 2;
	s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
	s = +(s * 100).toFixed(1);
	l = +(l * 100).toFixed(1);

	return [h, s + '%', l + '%'];
}

export function setThemeColor(themeColor) {
	if (!themeColor) return;
	const themeColorHSL = convertHexColorToHSL(themeColor);
	document.documentElement.style.setProperty('--theme-color', themeColor);
	document.documentElement.style.setProperty('--theme-color-hsl', themeColorHSL);
	document.documentElement.style.setProperty('--theme-color-h', themeColorHSL[0]);
	document.documentElement.style.setProperty('--theme-color-s', themeColorHSL[1]);
	document.documentElement.style.setProperty('--theme-color-l', themeColorHSL[2]);
}

export function setLuxonTimeZone(timeZone) {
	if (timeZone && IANAZone.isValidZone(timeZone)) {
		luxonSettings.defaultZone = timeZone;
	} else {
		luxonSettings.defaultZone = 'system';
	}
}

// adapted from https://stackoverflow.com/a/6491621 on 2022-12-06
export function getObjectValueAtPath(o, s) {
	// convert indexes to properties
	s = s.replace(/\[(\w+)\]/g, '.$1');
	// strip a leading dot
	s = s.replace(/^\./, '');
	var a = s.split('.');
	for (var i = 0, n = a.length; i < n; ++i) {
		var k = a[i];
		if (k in o) {
			o = o[k];
		} else {
			return;
		}
	}
	return o;
}

export function parseBool(rawValue, fallback) {
	if (rawValue === 'true') {
		return true;
	} else if (rawValue === 'false') {
		return false;
	} else {
		return fallback;
	}
}

export function isMobile() {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;
	return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(userAgent.toLowerCase());
}

export function getPitchId(numero, data) {
	let id;
	for (let item of data) {
		let value = item.value;
		if (value.includes('-')) {
			let [rango,] = value.split(' ');
			let [inicio, fin] = rango.split('-').map(Number);
			if (numero >= inicio && numero <= fin) {
				id = item.id;
			}
		}
	}
	return id;
}