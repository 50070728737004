<template>
	<div v-if="display === 'dropdown'" class="select-wrap">
		<select :id="htmlFieldName" :value="value ?? ''" @change="value = $event.target.value || null" :disabled="disabled">
			<option v-for="option in validOptions" :key="option.id" :value="option.id">
				{{ option.value }}
			</option>
		</select>
	</div>
	<div v-else-if="display === 'radio'" class="max-width-content">
		<div class="form-check-radio-group" v-for="option in validOptions" :key="option.id">
			<label class="check-radio-wrap">
				<input type="radio" :value="option.id" v-model="value" :id="`${htmlFieldName}_${option.id}`" :name="htmlFieldName" :disabled="disabled" />
				<span>{{ option.value }}</span>
			</label>
		</div>
	</div>
	<div v-else-if="display === 'swatch'" class="swatch-wrap">
		<div class="form-check-radio-group" v-for="option in validOptions" :key="option.id">
			<label class="check-radio-wrap" :class="{ selected: value === option.id }">
				<input type="radio" :value="option.id" v-model="value" :id="`${htmlFieldName}_${option.id}`" :name="htmlFieldName" class="hidden" :disabled="disabled" />
				<div class="swatch-img">
					<img v-if="option.image" :src="option.image.url" alt="" />
					<img v-else src="@/img/unknown-image.png" alt="" />
				</div>
				<!-- <span>{{ option.value }}</span> -->
			</label>
		</div>
	</div>
</template>

<script setup>
import EstimateFieldOption from '@/models/EstimateFieldOption';
import { useField } from 'vee-validate';
import { computed, inject, onMounted, toRef, watch } from 'vue';
import { useEstimateFieldsValues } from '@/composables/estimate';
const props = defineProps({
	name: {
		type: String,
		required: true,
	},
	display: {
		type: String,
		required: false,
		default: 'dropdown',
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const materialFieldId = {1: ['1.89'], 2: ['2.187']};

const modelLookup = inject('modelLookup');
const workflowId = inject('estimateWorkflowId');
const htmlFieldName = computed(() => 'field_' + workflowId + '_' + props.name.replace('.', '_'));
const { value, setValue } = useField(toRef(props, 'name'));
const model = computed(() => modelLookup(props.name));
const { value: parentItemId } = useField(computed(() => props.name.replace(/\.value$/, '.parentId')));

const { modelValues } = useEstimateFieldsValues(materialFieldId[workflowId] ?? []);

const validOptions = computed(() => {
	const options = model.value.validOptions !== undefined ? model.value.validOptions[parentItemId.value] ?? [] : [];
	let x = [...options];

	if (modelValues.materialType) {
		const materialTypes = model.value.field.materialTypes;

		if (materialTypes && materialTypes.length > 0) {
			const materialType = materialTypes.filter((x) => x.materialFieldId === modelValues.materialType.value);
			if (materialType.length > 0) {
				x = x.filter((option) => materialType.some((mt) => mt.optionId === option.id));
			}
		}
	}

	if (x.length === 0) { x = [new EstimateFieldOption({ id: '', value: 'N/A' })]; }

	return x;
});

watch(
	() => validOptions,
	() => {
		const selectedOption = validOptions.value.find((x) => x.id === value.value);
		if (selectedOption === undefined && validOptions.value[0].id !== '') {
			setValue(validOptions.value[0].id);
		}
	},
	{ deep: true }
);

watch(
	() => modelValues.materialType,
	() => {
		const selectedOption = validOptions.value.find((x) => x.id === value.value);
		if (selectedOption === undefined && validOptions.value[0].id !== '') {
			setValue(validOptions.value[0].id);
		}
	},
	{ immediate: true }
);
</script>
