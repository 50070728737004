import { safeComputed } from '@/composables/safeComputed';
import { createCallbacks, getObjectValueAtPath, round } from '@/helpers';
import Estimate from '@/models/Estimate';
import EstimateComparison from '@/models/EstimateComparison';
import EstimateField from '@/models/EstimateField';
import EstimateFieldOption from '@/models/EstimateFieldOption';
import { fieldInfo, fieldsToCopyParentQuantityFromChild, fieldsToToggleParentQuantityFromChild, mapping as fieldMapping } from '@/models/EstimateFields';
import EstimateFieldVariant from '@/models/EstimateFieldVariant';
import EstimateFieldVariantPriceType from '@/models/EstimateFieldVariantPriceType';
import EstimateItem from '@/models/EstimateItem';
import EstimateSections from '@/models/EstimateSections';
import EstimateWorkflows from '@/models/EstimateWorkflows';
import MessageType from '@/models/MessageType';
import { isChanged } from '@/models/_helper';
import { useForm } from 'vee-validate';
import { computed, inject, provide, reactive, ref, watch } from 'vue';
import { onBeforeRouteLeave, onBeforeRouteUpdate, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { array as yupArray, boolean as yupBoolean, number as yupNumber, object as yupObject, string as yupString } from 'yup';
import { onBeforeUnload } from './beforeUnload';

export const INVALID_FORM_MESSAGE = 'The page has errors which must be fixed before saving.'
const INVALID_FORM_NAVIGATION_MESSAGE = 'The page has errors which must be fixed before saving. Would you like to fix the errors or discard your changes?';
const WINDOW_UNSAVED_CHANGES = 'This window has unsaved changes. Would you like to cancel and go back to save them or discard your changes?'

function getFieldPath(fieldName, modelValues) {
	const path = [{
		propName: '',
		index: -1,
		value: modelValues,
	}];
	let modelValue = modelValues;
	if (typeof modelValue !== 'object' || modelValue === null) return path;
	for (const prop of fieldName.split('.')) {
		const info = {
			propName: prop,
			index: -1,
			value: null,
		};
		const match = /\[(\d+)\]$/.exec(prop);
		if (match) {
			info.index = parseInt(match[1]);
			info.propName = info.propName.substring(0, match.index);
		}
		info.value = info.index >= 0 ? modelValue[info.propName][info.index] : modelValue[info.propName];
		if (typeof info.value !== 'object' || info.value === null) break;
		path.unshift(info);
		modelValue = info.value;
	}
	return path;
}

/**
 * @param {Record<string, EstimateField>} fields field lookup object
 * @param {Record<string, EstimateItem[]>} estimateItemTreeByField the current estimate.itemTreeByField
 * @param {Record<string, any>} fullModelValues the full modelValues tree
 * @param {string} fieldName path taken through entire fieldValues object to get to this fieldValue
 * @returns {Record<string, string|number|null>} selected options (or values) organized by fieldId.
 */
export function getSelectedOptionsForVariant(fields, estimateItemTreeByField, fullModelValues, fieldName, id) {
	const path = getFieldPath(fieldName, fullModelValues);
	const modelValue = path[0].value;
	const selectedOptionIds = {}; // e.g. { '3.1' : '3.1.1', '3.2' : '3.2.1' }
	if (modelValue) {
		const targetField = fields[modelValue.fieldId];
		if (Array.isArray(modelValue)) return selectedOptionIds;
		if (targetField.variants.length > 0) {
			const fieldIds = new Set(Object.keys(targetField.variants[0].optionsMap));
			const includeWindowSize = targetField.ancestorIds.has('3.20');
			const qtyFieldIds = new Set(includeWindowSize ? ['3.24', '3.25', '3.26'] : []);
			qtyFieldIds.forEach(x => fieldIds.add(x));
			for (const fieldId of fieldIds) {
				const field = fields[fieldId];
				if (field.dataType !== 'dropdown' && !qtyFieldIds.has(fieldId)) { continue; }
				const fieldProp = field.property;
				// find value, first from modelValues (via path)
				let fieldValue = undefined;
				for (let i = 1; i < path.length; i++) {
					const pv = path[i].value;
					if (fieldProp in pv) {
						let fv = pv[fieldProp];
						fv = field.allowMultiple && Array.isArray(fv) ? (fv.length > 0 ? fv.find(x => x.id === id) : {}) : fv;
						fieldValue = fv ? (qtyFieldIds.has(fieldId) ? fv.quantity ?? 0 : fv.value ?? null) : null;
						break;
					}
				}
				// find value from estimate items if not in this form
				if (fieldValue === undefined) {
					const items = estimateItemTreeByField[fieldId];
					if (Array.isArray(items) && items.length > 0) {
						const parentIds = path.filter(x => x.propName).map(x => x.value.id);
						const item = items.find(x => parentIds.includes(x.parentItemId)) ?? items[0];
						fieldValue = qtyFieldIds.has(item.fieldId) ? item.quantity ?? 0 : item.fieldOptionId ?? null;
					}
				}
				selectedOptionIds[fieldId] = fieldValue;
			}
		}
	}
	return selectedOptionIds;
}

/**
 * @param {Record<string, EstimateField>} fields field lookup object
 * @param {Record<string, EstimateItem[]>} estimateItemTreeByField the current estimate.itemTreeByField
 * @param {Record<string, any>} fullModelValues the full modelValues tree
 * @param {object} fieldValue a specific field value, never an array
 * @param {string} path path taken through entire fieldValues object to get to this fieldValue
 * @param {Record<string, Record<string, string|number|null>>} o selected options object to add to
 */
function getSelectedOptionsForVariants_recursiveItem(fields, estimateItemTreeByField, fullModelValues, fieldValue, path, o) {
	if (fieldValue.children) {
		getSelectedOptionsForVariants_recursive(fields, estimateItemTreeByField, fullModelValues, fieldValue.children, path + '.children', o)
	}

	if (fields[fieldValue.fieldId] === undefined){
		return;
	}

	if (fields[fieldValue.fieldId].dataType === 'dropdown') {
		if (!o[fieldValue.parentId]) { o[fieldValue.parentId] = {}; }
		if (o[fieldValue.parentId][fieldValue.id]) { return; }
		o[fieldValue.parentId][fieldValue.id] = getSelectedOptionsForVariant(fields, estimateItemTreeByField, fullModelValues, path, fieldValue.id)
	}
}

/**
 * @param {Record<string, EstimateField>} fields field lookup object
 * @param {Record<string, EstimateItem[]>} estimateItemTreeByField the current estimate.itemTreeByField
 * @param {Record<string, any>} fullModelValues the full modelValues tree
 * @param {Record<string, any>} modelValues the modelValues tree, or a subtree of it, never an array, never an individual modelValue
 * @param {string} path path taken through entire fieldValues object to get to this fieldValue
 * @param {Record<string, Record<string, string|number|null>>} o selected options object to add to
 * @returns Selected values organized first by parentId, then by fieldId
 */
function getSelectedOptionsForVariants_recursive(fields, estimateItemTreeByField, fullModelValues, modelValues, path, o) {
	for (const key of Object.keys(modelValues)) {
		if (key === '_comparison') { continue; }
		const keyPath = path ? (path + '.' + key) : key;
		const fieldValue = modelValues[key]
		if (Array.isArray(fieldValue)) {
			for (let i = 0; i < fieldValue.length; i++) {
				getSelectedOptionsForVariants_recursiveItem(fields, estimateItemTreeByField, fullModelValues, fieldValue[i], keyPath + `[${i}]`, o)
			}
		} else {
			getSelectedOptionsForVariants_recursiveItem(fields, estimateItemTreeByField, fullModelValues, fieldValue, keyPath, o)
		}
	}
	return o;
}

/**
 * @param {Record<string, string|number|null>} selectedOptions
 * @param {EstimateField} field
 * @returns {EstimateFieldOption[]} valid options
 */
export function getValidFieldOptions(selectedOptions, field) {
	if (field.variants.length > 0) {
		const matches = field.variants.filter((x) => {
			// ignoring this field, do all the other options match?
			// matched if we don't find any options that don't match
			const optionsMatch = !x.options.find((x) => {
				const xf = EstimateFieldOption.getFieldId(x);
				const y = selectedOptions[xf];
				return xf != field.id && y && y !== x;
			});
			let pricingGood = true;
			if (x.priceType === EstimateFieldVariantPriceType.uiTiers) {
				const ui = selectedOptions['3.26']
				pricingGood = !!x.pricing.find(x => ui >= x.min && ui < x.max);
			} else if (x.priceType === EstimateFieldVariantPriceType.widthHeightTiers) {
				const width = selectedOptions['3.24']
				const height = selectedOptions['3.25']
				pricingGood = !!x.pricing.find(x => width >= x.min && width < x.max && height >= x.minHeight && height < x.maxHeight);
			}
			return pricingGood && optionsMatch;
		});
		const allowedOptionIds = matches.reduce((a, x) => {
			a.add(x.options.find((x) => EstimateFieldOption.getFieldId(x) === field.id));
			return a;
		}, new Set());
		return field.options.filter((x) => allowedOptionIds.has(x.id));
	} else {
		return field.options;
	}
}

/**
 * @param {Record<string, any>} modelValues the modelValues tree, or a subtree of it, never an array, never an individual modelValue
 * @param {string} path path taken through entire modelValues object to get to this modelValues object
 * @param {Record<string, object[]>} o keys are fieldIds, values are arrays of objects of shape { value: object, path: string }
 * @returns modelValues organized by fieldId
 */
function organizeFieldValuesByField_recursive(modelValues, path, o, fields, fieldIds, missing) {
	const expectedFields = fieldIds.reduce((o, x) => { o[fields[x].property] = fields[x]; return o; }, {});
	for (const key of Object.keys(expectedFields)) {
		if (key === '_comparison') { continue; }
		const field = expectedFields[key];
		const keyPath = path ? (path + '.' + key) : key;
		if (!(key in modelValues)) {
			if (field.allowMultiple) {
				missing.push(keyPath);
			} else {
				console.error('Model values missing expected property: ' + keyPath);
			}
			continue;
		}
		const fieldValue = modelValues[key]
		if (Array.isArray(fieldValue)) {
			for (let i = 0; i < fieldValue.length; i++) {
				const { fieldId, children } = fieldValue[i];
				(fieldId in o ? o[fieldId] : (o[fieldId] = [])).push({ value: fieldValue[i], path: keyPath + `[${i}]` });
				if (children) {
					organizeFieldValuesByField_recursive(children, keyPath + `[${i}].children`, o, fields, field.childIds, missing)
				}
			}
		} else {
			const { fieldId, children } = fieldValue;
			(fieldId in o ? o[fieldId] : (o[fieldId] = [])).push({ value: fieldValue, path: keyPath });
			if (children) {
				organizeFieldValuesByField_recursive(children, keyPath + '.children', o, fields, field.childIds, missing)
			}
		}
	}
	return o;
}

/**
 * @param {Record<string, EstimateItem[]>} estimateItemTreeByField the current estimate.itemTreeByField
 * @param {Record<string, EstimateField>} fields field lookup object
 * @param {string[]} fieldOrder sorted field IDs indicating the order fields should be processed in for optimized variant calculations
 * @param {Record<string, any>} modelValues the full modelValues tree
 * @returns
 */
function fixFieldValueOptions(estimateItemTreeByField, fields, fieldOrder, fieldIds, modelValues) {
	const validOptions = {};
	const missing = [];
	const organizedModelValues = organizeFieldValuesByField_recursive(modelValues, '', {}, fields, fieldIds, missing);
	const changes = missing.length === 0 ? [] : missing.map(path => ({ fieldName: path, value: [] }));
	const queue = sortFieldIds(Object.keys(organizedModelValues), fieldOrder);

	while (queue.length > 0) {
		const fieldId = queue.shift();
		const field = fields[fieldId];
		if (field.dataType === 'number') {
			if (field.min === field.max) {
				for (const { value: fieldValue, path } of organizedModelValues[fieldId]) {
					// fix invalid quantity
					if (fieldValue.quantity < field.min || fieldValue.quantity > field.max) {
						let newQuantity = field.min;
						if (newQuantity !== fieldValue.quantity) {
							fieldValue.quantity = newQuantity;
							changes.push({ fieldName: path + '.quantity', value: newQuantity });
						}
					}
				}
			}
		} else if (field.dataType === 'dropdown') {
			for (const { value: fieldValue, path } of organizedModelValues[fieldId]) {
				const selectedOptions = getSelectedOptionsForVariant(fields, estimateItemTreeByField, modelValues, path, fieldValue.id);
				if (!(field.id in validOptions)) { validOptions[field.id] = {}; }
				const validFieldOptions = validOptions[field.id][fieldValue.parentId] = getValidFieldOptions(selectedOptions, field);
				// fix invalid options
				if (!(fieldValue.value === null && field.allowNull) && (!fieldValue.value || !validFieldOptions.find((x) => x.id === fieldValue.value))) {
					let newValue = validFieldOptions.length > 0 ? validFieldOptions[0].id : null;
					if (newValue !== fieldValue.value) {
						fieldValue.value = newValue;
						changes.push({ fieldName: path + '.value', value: newValue });
					}
				}
			}
		}
	}
	return {
		validOptions,
		modelValues,
		changes
	};
}

function setupFieldValidation(fieldId, fieldsRef, validOptionsRef) {
	const field = fieldsRef.value[fieldId];
	const fieldModel = { field };
	const validator = { id: yupNumber().required(), parentId: yupNumber().nullable(), };
	if (field.dataType === 'boolean') {
		validator.value = yupBoolean().label(field.name);
	} else if (field.dataType === 'dropdown') {
		fieldModel.validOptions = computed(() => validOptionsRef.value[fieldId]);
		validator.value = yupString()
			.nullable()
			.test('validOption', `Invalid ${field.name}`, (value, context) => {
				/*
				when there is a valid option:
					when !field.allowQuantityChange || quantity > 0:
						value is not null and must be a valid option
					else:
						value is null or a be a valid option
				else:
					value must be null
				*/
				if (fieldInfo[context.parent.fieldId] != undefined 
					&& fieldInfo[context.parent.fieldId].placeHolderId != undefined 
					&& context.parent.value == fieldInfo[context.parent.fieldId].placeHolderId){
					return false;
				}
				const key = context.parent.parentId;
				const validOptions = fieldModel.validOptions.value[key] ?? [];
				const isValidOption = () => validOptions.findIndex(x => x.id === value) >= 0;
				return validOptions.length > 0 ? (
					(!field.allowQuantityChange || context.parent.quantity > 0)
						? value !== null && isValidOption()
						: value === null || isValidOption()
				) : value === null;
			})
			.label(field.name);
	} else if (field.dataType !== 'number') { // field.dataType === 'string'
		validator.value = yupString().nullable().label(field.name);
	}

	if (field.allowQuantityChange) {
		validator.quantity = yupNumber().typeError('${path} is required.').nullable().min(field.min).max(field.max).step(field.min, field.step).label(field.dataType === 'number' ? field.name : 'Quantity');
	}
	if (field.allowPriceChange) {
		validator.materialCost = yupNumber().typeError('${path} is required.').nullable().min(0).max(1000000000).step(0, 0.01).label('Material cost');
		validator.laborCost = yupNumber().typeError('${path} is required.').nullable().min(0).max(1000000000).step(0, 0.01).label('Labor cost');
		validator.materialPrice = yupNumber().typeError('${path} is required.').nullable().min(0).max(1000000000).step(0, 0.01).label('Material price');
		validator.laborPrice = yupNumber().typeError('${path} is required.').nullable().min(0).max(1000000000).step(0, 0.01).label('Labor price');
	}

	return { fieldModel, validator };
}


function getFieldValueFromEstimateItem(estimateItem, field, forDisplay) {
	// this (when !forDisplay) must match EstimateField.setDefaultValue
	const itemValue = { id: estimateItem.id, parentId: estimateItem.parentItemId, fieldId: field.id };
	if (forDisplay) {
		if (field.dataType === 'number') {
			itemValue.value = estimateItem.quantity;
		} else {
			itemValue.value = estimateItem.value;
		}
		if (field.allowQuantityChange && field.dataType !== 'number') {
			itemValue.quantity = estimateItem.quantity;
		}
	} else {
		if (field.dataType === 'boolean') {
			itemValue.value = estimateItem.value === 'true';
		} else if (field.dataType === 'string') {
			itemValue.value = estimateItem.value;
		} else if (field.dataType === 'dropdown') {
			itemValue.value = estimateItem.fieldOptionId;
		}
		if (field.allowQuantityChange) {
			itemValue.quantity = estimateItem.quantity;
		}
		if (field.allowPriceChange) {
			itemValue.materialCost = estimateItem.materialCost;
			itemValue.laborCost = estimateItem.laborCost;
			itemValue.materialPrice = estimateItem.materialPrice;
			itemValue.laborPrice = estimateItem.laborPrice;
		}
	}
	return itemValue;
}

export function sortFieldIds(fieldIds, fieldOrder) {
	fieldIds = Array.from(fieldIds);
	fieldIds.sort((a, b) => {
		const ai = fieldOrder.indexOf(a);
		const bi = fieldOrder.indexOf(b);
		if (ai < 0 && bi >= 0) {
			return -1;
		} else if (bi < 0 && ai >= 0) {
			return 1;
		}
		return ai - bi;
	});
	return fieldIds;
}

export function getFieldValuesFromEstimate(itemTreeByField, fields, fieldIds, forDisplay = false, setDefaultOption = false, reset = false) {
	const values = {};
	for (const fieldId of fieldIds) {
		const field = fields[fieldId];
		values[field.property] = getFieldValuesFromEstimate_recursive(field, null, itemTreeByField, fields, forDisplay, setDefaultOption, reset);
	}
	return values;
}

function getFieldValuesFromEstimate_recursive(field, parentItemId, itemTreeByField, fields, forDisplay, setDefaultOption, reset) {
	const items = reset ? [] : (itemTreeByField[field.id] ?? []).filter(x => x.parentItemId === parentItemId);
	const itemsId = (itemTreeByField[field.id] ?? []).filter(x => x.parentItemId === parentItemId);
	if (items.length === 0 && (!field.allowMultiple || (field.defaultOne && (parentItemId === null || isTempItemId(parentItemId)))) && !forDisplay) {
		const itemValue = itemsId.find(x => x.fieldId === field.id);
		const defaultItem = new EstimateItem({ id: itemValue ? itemValue.id : getTempItemId(), parentItemId: parentItemId });
		let ui = 0, width = 0, height = 0;
		if (field.ancestorIds.has('3.20')) {
			ui = parseFloat(((itemTreeByField['3.26'] ?? []).find(x => x.parentItemId === parentItemId) ?? {}).value || '0');
			width = parseFloat(((itemTreeByField['3.24'] ?? []).find(x => x.parentItemId === parentItemId) ?? {}).value || '0');
			height = parseFloat(((itemTreeByField['3.25'] ?? []).find(x => x.parentItemId === parentItemId) ?? {}).value || '0');
		}
		updateEstimateItemFromValue(field, defaultItem, field.defaultValue, undefined, ui, width, height);
		if (field.dataType === 'dropdown' && !setDefaultOption) {
			defaultItem.value = null;
			defaultItem.fieldOptionId = null;
		}
		items.push(defaultItem);
	}

	let value = field.allowMultiple ? [] : {};
	for (const item of items) {
		const itemValue = getFieldValueFromEstimateItem(item, field, forDisplay);
		if (field.childIds.length > 0) {
			itemValue.children = {};
			for (const childFieldId of field.childIds) {
				const childField = fields[childFieldId];
				itemValue.children[childField.property] = getFieldValuesFromEstimate_recursive(childField, item.id, itemTreeByField, fields, forDisplay, setDefaultOption, reset);
			}
		}

		if (field.allowMultiple) {
			value.push(itemValue);
		} else {
			value = itemValue;
		}
	}
	return value;
}

// tempItemIds are only even negative integers
// idb localIds are only odd negative integers
const getTempItemId = (function () {
	let tempId = 0;
	return () => (tempId -= 2);
})();
function isTempItemId(id) {
	return id < 0 && id % -2 === 0;
}

function getEstimateItem(itemTreeByField, fieldId, itemId, parentItemId) {
	const a = itemTreeByField[fieldId];
	if (a) {
		const existingIndex = a.findIndex(x => x.id === itemId && x.parentItemId === parentItemId);
		if (existingIndex >= 0) {
			return new EstimateItem(a[existingIndex]);
		}
	}
	return new EstimateItem({ id: (itemId > 0 || itemId < 0) ? itemId : getTempItemId(), parentItemId, fieldId });
}

function updateEstimateItemFromValue(field, estimateItem, modelData, selectedOptions, ui = 0, width = 0, height = 0) {
	if (selectedOptions) {
		selectedOptions = selectedOptions[modelData.parentId]
		if (selectedOptions) {
			selectedOptions = selectedOptions[modelData.id];
		}
	}
	let fieldOptionId = null;
	if (field.dataType === 'boolean') {
		estimateItem.materialCost = field.materialCost;
		estimateItem.laborCost = field.laborCost;
		estimateItem.materialPrice = field.materialPrice;
		estimateItem.laborPrice = field.laborPrice;
		estimateItem.value = (modelData.value || false).toString();
		estimateItem.quantity = modelData.value || field.defaultValue.value === true ? 
			field.allowQuantityChange ? modelData.quantity : 1 : 0;
	} else if (field.dataType === 'number') {
		estimateItem.materialCost = field.materialCost;
		estimateItem.laborCost = field.laborCost;
		estimateItem.materialPrice = field.materialPrice;
		estimateItem.laborPrice = field.laborPrice;
		estimateItem.value = modelData.quantity.toString();
		estimateItem.quantity = modelData.quantity;
	} else if (field.dataType === 'string') {
		estimateItem.materialCost = field.materialCost;
		estimateItem.laborCost = field.laborCost;
		estimateItem.materialPrice = field.materialPrice;
		estimateItem.laborPrice = field.laborPrice;
		estimateItem.value = modelData.value === null ? null : modelData.value.toString();
		estimateItem.quantity = field.allowQuantityChange ? modelData.quantity : 1;
	} else if (field.dataType === 'dropdown') {
		fieldOptionId = modelData.value;
		const fieldOption = fieldOptionId ? field.options.find(x => x.id === fieldOptionId) : null;
		let remove = false;
		if (fieldOption) {
			estimateItem.value = fieldOption.value;
			estimateItem.quantity = field.allowQuantityChange && fieldOptionId !== '2.96.1' ? modelData.quantity : 1;
			if (field.variants.length > 0 && selectedOptions && estimateItem.quantity > 0) {
				const matches = field.variants.filter((x) => {
					// matched if we don't find any options that don't match
					return !x.options.find((x) => {
						const xf = EstimateFieldOption.getFieldId(x);
						const y = selectedOptions[xf];
						return y !== x;
					});
				});
				if (matches.length > 0) {
					const match = matches[0];
					if (match instanceof EstimateFieldVariant) {
						if (match.priceType == EstimateFieldVariantPriceType.uiMultipler) {
							estimateItem.materialCost = round(Math.max(match.minMaterialCost, match.materialCost * ui), 2);
							estimateItem.laborCost = round(match.laborCost * ui, 2);
							estimateItem.materialPrice = round(Math.max(match.minMaterialPrice, match.materialPrice * ui), 2);
							estimateItem.laborPrice = round(match.laborPrice * ui, 2);
						} else if (match.priceType === EstimateFieldVariantPriceType.uiTiers) {
							const price = match.pricing.find(x => ui >= x.min && ui < x.max);
							if (price) {
								estimateItem.materialCost = price.materialCost;
								estimateItem.laborCost = price.laborCost;
								estimateItem.materialPrice = price.materialPrice;
								estimateItem.laborPrice = price.laborPrice;
							} else {
								console.error(`Variant for field ${field.id} pricing tier missing on save`);
								remove = true;
							}
						} else if (match.priceType === EstimateFieldVariantPriceType.widthHeightTiers) {
							const price = match.pricing.find(x => width >= x.min && width < x.max && height >= x.minHeight && height < x.maxHeight);
							if (price) {
								estimateItem.materialCost = price.materialCost;
								estimateItem.laborCost = price.laborCost;
								estimateItem.materialPrice = price.materialPrice;
								estimateItem.laborPrice = price.laborPrice;
							} else {
								console.error(`Variant for field ${field.id} pricing tier missing on save`);
								remove = true;
							}
						} else {
							// standard
							estimateItem.materialCost = match.materialCost;
							estimateItem.laborCost = match.laborCost;
							estimateItem.materialPrice = match.materialPrice;
							estimateItem.laborPrice = match.laborPrice;
						}
					}
				} else {
					console.error(`Variant for field ${field.id} missing on save`);
					remove = true;
				}
			} else {
				estimateItem.materialCost = fieldOption.materialCost;
				estimateItem.laborCost = fieldOption.laborCost;
				estimateItem.materialPrice = fieldOption.materialPrice;
				estimateItem.laborPrice = fieldOption.laborPrice;
			}
		} else {
			// field option does not exist
			remove = true;
		}
		if (remove) {
			estimateItem.materialCost = 0;
			estimateItem.laborCost = 0;
			estimateItem.materialPrice = 0;
			estimateItem.laborPrice = 0;
			estimateItem.value = null;
			estimateItem.quantity = 0;
			fieldOptionId = null;
		}
	}
	if (field.allowPriceChange) {
		estimateItem.materialPrice = modelData.materialPrice;
		estimateItem.laborPrice = modelData.laborPrice;
		estimateItem.materialCost = modelData.materialCost;
		estimateItem.laborCost = modelData.laborCost;
	}
	// estimateItem.parentItemId = null;
	estimateItem.fieldId = field.id;
	estimateItem.fieldOptionId = fieldOptionId;
}

export function getEstimateItemsFromValues(modelValues, fieldIds, estimateItemTreeByField, fields) {
	const items = [];
	const selectedOptions = getSelectedOptionsForVariants_recursive(fields, estimateItemTreeByField, modelValues, modelValues, '', {});
	for (let i = 0; i < fieldIds.length; i++) {
		const field = fields[fieldIds[i]];
		items.push(...getEstimateItemsFromValues_recursive(modelValues, null, field, fields, selectedOptions, estimateItemTreeByField));
	}

	const mapping = [
		{ source: '1.168', target: '1.132' }, // changing roofing.totalSquaresForMaterial updates roofing.package
		{ source: '1.168', target: '1.133' }, // changing roofing.totalSquaresForMaterial updates roofing.shingleColor
		{ source: '1.169', target: '1.134' }, // changing roofing.totalSquaresForLabor updates roofing.packageInstallation
		{ source: '2.29', target: '2.88' }, // changing siding.totalSquaresForMaterialPackage updates siding.sidingPackage
		{ source: '2.29', target: '2.89' }, // changing siding.totalSquaresForMaterialPackage updates siding.sidingColor
		{ source: '2.29', target: '2.90' }, // changing siding.totalSquaresForMaterialPackage updates siding.sidingReveal
		{ source: '2.100', target: '2.104' }, // changing siding.totalSquaresForLaborPackage updates siding.sidingPackageInstallation
	];

	for (let i = 0; i < mapping.length; i++) {
		const map = mapping[i];
		if (fieldIds.includes(map.source)) {
			let sourceItemQuantity = (items.find(x => x.fieldId === map.source) ?? { quantity: 0 }).quantity;
			let targetItem = items.find(x => x.fieldId === map.target);
			if (!targetItem) {
				targetItem = (estimateItemTreeByField[map.target] ?? [])[0];
				if (targetItem) {
					targetItem = new EstimateItem(targetItem);
					items.push(targetItem);
				}
			}
			if (targetItem) {
				targetItem.quantity = sourceItemQuantity;
			}
		} 
		
		if (fieldIds.includes(map.target) && (map.target === '1.132' || map.target === '1.133' || map.target === '1.134') && items.filter(x => x.fieldId === map.source && x.value !== null).length > 0){
			let sourceItemQuantity = (items.find(x => x.fieldId === map.source) ?? { quantity: 0 }).quantity;
			let targetItem = items.find(x => x.fieldId === map.target);
			
			if (targetItem) {
				targetItem.quantity = sourceItemQuantity;
			}
		}
	}

	return items;
}

function getEstimateItemsFromValues_recursive(values, windowSize, field, fields, selectedOptions, itemTreeByField) {
	const items = [];
	const length = field.allowMultiple ? (Array.isArray(values[field.property]) ? values[field.property].length : 0) : 1;
	for (let i = 0; i < length; i++) {
		let modelData = field.allowMultiple ? values[field.property][i] : values[field.property];
		const estimateItem = getEstimateItem(itemTreeByField, field.id, modelData.id, modelData.parentId);

		let parentQuantity = null;
		const descendantItems = [];
		if (field.childIds.length > 0) {
			const parentValue = [];
			if (field.id === '3.20') {
				windowSize = {
					ui: modelData.children.ui.quantity,
					width: modelData.children.width.quantity,
					height: modelData.children.height.quantity,
				};
			}
			for (const childFieldId of field.childIds) {
				const childField = fields[childFieldId];
				const theseDescendantItems = getEstimateItemsFromValues_recursive(modelData.children, windowSize, childField, fields, selectedOptions, itemTreeByField);
				if (theseDescendantItems.length === 0) {
					theseDescendantItems.push(getEstimateItem(itemTreeByField, childField.id, 0, modelData.id))
				}
				const theseChildItemsSummary = [];
				for (const descendantItem of theseDescendantItems) {
					// childItem.parentItemId = estimateItem.id;
					// override parent item quantity in some special cases
					parentQuantity = overrideEstimateItemParentQuantityFromChild(field, childField, descendantItem, parentQuantity);
					if (!(descendantItem.quantity > 0)) continue;
					descendantItems.push(descendantItem);
					if (descendantItem.parentItemId === estimateItem.id) {
						// include summary of child item in parent item value
						let childValue = '';
						if (childField.dataType === 'dropdown' || childField.dataType === 'string') {
							childValue = descendantItem.value;
							if (childField.allowQuantityChange && childField.units) {
								childValue += ` ${descendantItem.quantity} ${childField.units}`;
							} else if (childField.allowQuantityChange) {
								childValue += ` Qty: ${descendantItem.quantity}`;
							}
						} else if (childField.dataType === 'number') {
							childValue = descendantItem.quantity + (childField.units ? ' ' + childField.units : '');
						} else { // boolean
							childValue = descendantItem.value;
						}
						if (childValue) {
							theseChildItemsSummary.push(childField.name + ': ' + childValue);
						} else {
							theseChildItemsSummary.push(childField.name + ': N/A');
						}
					}
				}
				if (theseChildItemsSummary.length > 0) {
					parentValue.push(theseChildItemsSummary.join('; '))
				}
			}

			modelData = Object.assign({}, modelData);
			modelData.value = parentValue.join(', ');
		}

		updateEstimateItemFromValue(field, estimateItem, modelData, selectedOptions, (windowSize ?? {}).ui, (windowSize ?? {}).width, (windowSize ?? {}).height);
		if (parentQuantity !== null && field.id !== '7.60') {
			estimateItem.quantity = parentQuantity;
		}

		const quantity = estimateItem.fieldId === '1.36' ? 1 : estimateItem.quantity;

		if (estimateItem.value && quantity > 0) {
			items.push(estimateItem);
			for (const descendantItem of descendantItems) {
				items.push(descendantItem);
				if (descendantItem.parentItemId === estimateItem.id) {
					if (descendantItem.fieldId === '4.3') {
						estimateItem.materialCost += descendantItem.materialCost;
						estimateItem.laborCost += descendantItem.laborCost;
						estimateItem.materialPrice += descendantItem.materialPrice;
						estimateItem.laborPrice += descendantItem.laborPrice;
					} else {
						estimateItem.materialCost += descendantItem.materialCostTotal;
						estimateItem.laborCost += descendantItem.laborCostTotal;
						estimateItem.materialPrice += descendantItem.materialPriceTotal;
						estimateItem.laborPrice += descendantItem.laborPriceTotal;
					}
				}
			}
		}
	}
	return items;
}

function overrideEstimateItemParentQuantityFromChild(field, childField, childEstimateItem, parentQuantity) {
	// override parent item quantity in some special cases
	if (fieldsToCopyParentQuantityFromChild.find(x => x.parentFieldId === field.id && x.childFieldId === childField.id)) {
		parentQuantity = (parentQuantity ?? 0) + childEstimateItem.quantity;
	} else if (fieldsToToggleParentQuantityFromChild.find(x => x.parentFieldId === field.id && x.childFieldId === childField.id)) {
		parentQuantity = Math.max((parentQuantity ?? 0), childEstimateItem.quantity > 0 ? 1 : 0);
	}
	return parentQuantity;
}

export function getFieldModel(fields, fieldIds) {
	const model = {};
	for (const fieldId of fieldIds) {
		const field = fields[fieldId];
		const fieldModel = { field };
		if (field.childIds.length > 0) {
			fieldModel.children = getFieldModel(fields, field.childIds);
		}
		model[field.property] = fieldModel;
	}
	return model;
}

export function getDescendantIds(itemId, tree, set) {
	if (itemId) {
		set.add(itemId);
		const a = tree[itemId];
		if (Array.isArray(a)) {
			for (const childItem of a) {
				getDescendantIds(childItem.id, tree, set);
			}
		}
	}
	return set;
}

function setModelValueTempItemId_recursive(value, parentValue) {
	const array = Array.isArray(value) ? value : [value];
	for (const x of array) {
		x.id = getTempItemId();
		x.parentId = parentValue ? parentValue.id : null;
		if (x.children) {
			for (const child of Object.values(x.children)) {
				setModelValueTempItemId_recursive(child, x);
			}
		}
	}
	return value;
}

const modelLookupRegex = /(\[\d+\])|(\.(fieldId|id|laborCost|laborPrice|materialCost|materialPrice|parentId|quantity|value)$)/g;

function useEstimateFields(estimateRef, fieldIds, includeComparison, itemIdRef, { onFetchData = [] } = {}) {
	const $store = useStore();
	const fieldsRef = safeComputed(() => $store.state.estimator.fields);
	const fieldIdsWithParentId = fieldIds.filter(x => fieldsRef.value[x].parentId);
	if (fieldIdsWithParentId.length > 0) {
		console.error('useEstimateFields was called with field ids which have a parent field id: ' + JSON.stringify(fieldIdsWithParentId));
	}
	const { callCallbacks: fetchDataCallbacks, addCallback: addFetchDataCallback } = createCallbacks();
	for (const callback of onFetchData) { addFetchDataCallback(callback); }

	const estimateItemTreeByFieldRef = computed(() => {
		if (!itemIdRef) {
			return estimateRef.value.itemTreeByField;
		} else if (itemIdRef.value) {
			const parentItemIds = getDescendantIds(itemIdRef.value, estimateRef.value.itemTreeByParent, new Set());
			const e = new Estimate();
			e.items = estimateRef.value.items.filter(x => parentItemIds.has(x.id) || parentItemIds.has(x.parentItemId));
			return e.itemTreeByField;
		} else {
			return {};
		}
	});
	const fieldOrderRef = safeComputed(() => $store.state.estimator.fieldOrder[EstimateField.getWorkflowId(fieldIds[0])]);
	function modelLookup(fieldName) { 
		return getObjectValueAtPath(model, fieldName.replaceAll(modelLookupRegex, '')); 
	}
	provide('modelLookup', modelLookup);

	const validOptionsRef = ref(null);
	const defaultValues = getData();
	const modelValuesRef = ref(defaultValues);
	const defaultValuesMultiple = {};

	const { schema, model: rawModel } = setupModel_recursive(fieldIds, fieldsRef, validOptionsRef, defaultValuesMultiple, includeComparison);
	const model = reactive(rawModel);

	function getData() {
		let values = getFieldValuesFromEstimate(estimateItemTreeByFieldRef.value, fieldsRef.value, fieldIds, false, true, false);
		fetchDataCallbacks(values);
		const updated = fixFieldValueOptions(estimateItemTreeByFieldRef.value, fieldsRef.value, fieldOrderRef.value, fieldIds, values);
		values = updated.modelValues;
		validOptionsRef.value = updated.validOptions;
		return values;
	}

	const formContext = useForm({ validationSchema: yupObject(schema), initialValues: defaultValues });
	const { resetForm, validate, meta, errors, setErrors, setFieldValue: setModelValue, setValues: setModelValues } = formContext;
	modelValuesRef.value = formContext.values;

	function updateValidOptions() {
		const updated = fixFieldValueOptions(estimateItemTreeByFieldRef.value, fieldsRef.value, fieldOrderRef.value, fieldIds, JSON.parse(JSON.stringify(modelValuesRef.value)));
		for (const { fieldName, value } of updated.changes) {
			setModelValue(fieldName, value);
		}
		validOptionsRef.value = updated.validOptions;
	}
	watch(estimateItemTreeByFieldRef, updateValidOptions);
	watch(modelValuesRef, updateValidOptions, { deep: true });

	function getNewFieldArrayItem(fieldName) {
		const field = modelLookup(fieldName).field;
		const v = JSON.parse(JSON.stringify(defaultValuesMultiple[field.id]));

		const dot = fieldName.lastIndexOf('.children.');
		const parentValue = dot >= 0 ? getObjectValueAtPath(modelValuesRef.value, fieldName.substring(0, dot)) : null;

		return setModelValueTempItemId_recursive(v, parentValue);
	}
	provide('getNewFieldArrayItem', getNewFieldArrayItem);

	/**
	 * @param {Array} items
	*/
	function getItems(items) {
		const newItems = getEstimateItemsFromValues(modelValuesRef.value, fieldIds, estimateItemTreeByFieldRef.value, fieldsRef.value);
		items.push(...newItems);
	}

	return {
		getItems,
		modelValues: modelValuesRef.value,
		modelLookup,
		getData,
		meta,
		validate,
		model,
		errors,
		setModelValue,
		setModelValues,
		resetForm,
		setErrors,
		getNewFieldArrayItem,
	};
}

/**
 * @param {string[]} fieldIds
 * @param {Record<string, any>} modelValuesRef
 * @param {import('vue').Ref<Record<string, EstimateField>>} fieldsRef
 * @param {import('vue').Ref<EstimateItem[]>} estimateItemTreeByFieldRef
 * @param {Record<string, any>} defaultValuesMultiple
 * @param {boolean} includeComparison
 * @returns
 */
function setupModel_recursive(fieldIds, fieldsRef, validOptionsRef, defaultValuesMultiple, includeComparison) {
	const schema = {};
	const model = {};
	const defaultValues = {};
	
	for (const fieldId of fieldIds) {
		const field = fieldsRef.value[fieldId];
		const defaultValue = JSON.parse(JSON.stringify(field.defaultValue));
		const { fieldModel, validator } = setupFieldValidation(fieldId, fieldsRef, validOptionsRef);

		if (field.childIds.length > 0) {
			const childOutput = setupModel_recursive(field.childIds, fieldsRef, validOptionsRef, defaultValuesMultiple, includeComparison);
			fieldModel.children = childOutput.model;
			defaultValue.children = childOutput.defaultValues;
			validator.children = yupObject(childOutput.schema);
		}

		model[field.property] = fieldModel;
		if (field.allowMultiple) {
			defaultValuesMultiple[field.id] = defaultValue;
			defaultValues[field.property] = field.defaultOne ? [JSON.parse(JSON.stringify(defaultValue))] : [];
			schema[field.property] = yupArray().ensure().of(yupObject(validator))
			.test('pitch-sum', 'The total number of squares must match the total number of squares for the material.', function (value) {
				let isField = false;
				let result = true;

				value.map((x) => {
					if (x.fieldId == '1.43') {
						isField = true;
					}
				});

				if (isField) {
					var total = value.reduce((sum, x) => sum + x.quantity, 0);
					const rounded = Math.round(total / (1 / 3)) * (1 / 3)

					const quantity = this.parent.totalSquaresForMaterial.quantity + this.parent.totalSquaresForMaterial.membraneArea;

					if (round(rounded, 3) != quantity) {
						result = false;
					}
				}

				return result;
			});
		} else {
			defaultValues[field.property] = defaultValue;
			schema[field.property] = yupObject(validator);

			if (includeComparison && field.isComparison && field.allowQuantityChange) {
				const key = '_comparison';
				model[key] = {};
				defaultValues[key] = [];
				schema[key] = yupArray().ensure().of(yupString())
					.when(field.property + '.quantity', {
						is: x => x > 0,
						then: x => x.min(1, 'Select 1-3 options for comparison.').max(3, 'Select 1-3 options for comparison.')
					});
			}
		}
	}

	return { schema, model, defaultValues };
}

export function useRecommended(fieldsRef) {
	function getRecommendedConversion(number, field, optionId) {
		if (field.dataType === 'dropdown') {
			if (optionId) {
				const option = field.options.find((x) => x.id === optionId);
				if (option) {
					switch (number) {
						case 1: return option.recommendedConversion1;
						case 2: return option.recommendedConversion2;
						case 3: return option.recommendedConversion3;
					}
				}
			}
		} else {
			switch (number) {
				case 1: return field.recommendedConversion1;
				case 2: return field.recommendedConversion2;
				case 3: return field.recommendedConversion3;
			}
		}
		return 0;
	}

	function getAnyRecommendedConversion(number, item) {
		const field = fieldsRef.value[item.fieldId];
		const optionId = item.fieldOptionId;
		return getRecommendedConversion(number, field, optionId);
	}
	return {
		getRecommendedConversion,
		getAnyRecommendedConversion,
	};
}

export function useEstimateSection(sectionId, isPartial, removeDefaultOne = []) {
	const $router = useRouter();
	const $store = useStore();
	const estimate = safeComputed(() => $store.state.estimator.estimate);
	const fields = safeComputed(() => $store.state.estimator.fields);
	const fieldIds = fieldMapping[sectionId];
	const confirmDialog = inject('confirmDialog')
	const workflowId = EstimateSections.getWorkflowId(sectionId);
	const loading = ref(false);
	const stay = ref(false);
	const recommendedItems = ref({});
	provide('loading', loading);

	const saveEstimateChanges = inject('saveEstimateChanges');
	provide('estimateSectionId', sectionId);

	if (!isPartial && workflowId != EstimateWorkflows.repairs) {
		// validate that step is enabled complete or current
		const steps = $store.getters['estimator/estimateSteps'](workflowId);
		let step = steps.find(x => x.id === sectionId);
		if (!step || !(step.complete || step.current)) {
			step = $store.getters['estimator/getCurrentStep'](workflowId, steps);
			if (step) {
				$router.push(step.url);
			} else {
				$router.push(`/estimator/estimate/${estimate.value.id}`);
			}
		}
	}

	const {
		getItems,
		modelValues,
		modelLookup,
		getData,
		meta,
		model,
		errors,
		setModelValue,
		setModelValues,
		resetForm,
		validate,
		setErrors,
		getNewFieldArrayItem,
	} = useEstimateFields(estimate, fieldIds, true, undefined, {
		onFetchData: [
			(values) => {
				if (Array.isArray(removeDefaultOne) && removeDefaultOne.length > 0) {
					for (const key of removeDefaultOne) {
						if (Array.isArray(values[key])) {
							for (let i = 0; i < values[key].length; i++) {
								if (isTempItemId(values[key][i].id)) {
									values[key].splice(i, 1);
									i--;
								}
							}
						}
					}
				}
				for (let i = 0; i < fieldIds.length; i++) {
					const field = fields.value[fieldIds[i]];
					if (field.isComparison) {
						values['_comparison'] = estimate.value.comparisons.filter(x => x.workflowId === workflowId).map(x => x.fieldOptionId);
					}
				}
			}
		]
	});

	provide('model', model);

	function fetchData() {
		loading.value = true;
		const values = getData();
		resetForm({ values: values });
		loading.value = false;
	}

	function validateRecommendedItems() {
		let result = true;

		const keys = Object.keys(modelValues)
		for (const key of keys) {
			const modelValue = modelValues[key];
			if (Array.isArray(modelValue)) {
				if (!validateRecommendedItemsArray(modelValue)) {
					result = false;
					break;
				}
			}
			var recommendedItem = recommendedItems[modelValue.fieldId];
			if (recommendedItem) {
				if (modelValue.quantity == 0 && recommendedItem > 0) {
					result = false;
					break;
				}
			}
		}
		return result;
	}

	function validateRecommendedItemsArray(modelValues, index) {
		let result = true;
		let i = 0;
		for (const modelValue of modelValues) {
			var recommendedItem = recommendedItems[modelValue.fieldId] ? recommendedItems[modelValue.fieldId][index ?? i] : recommendedItems[modelValue.fieldId];
			if (recommendedItem) {
				if (modelValue.quantity == 0 && recommendedItem > 0) {
					result = false;
					break;
				}
			} else if (modelValue.children) {
				if (!validateRecommendedItemsArray(Object.values(modelValue.children), i)) {
					result = false;
					break;
				}
			}

			i++;
		}
		return result;
	}

	const save = async () => {
		if (!(await validate()).valid) {
			$store.dispatch('addMessage', { message: INVALID_FORM_MESSAGE, type: MessageType.warning, autoClose: true });
			return false;
		}

		if (!validateRecommendedItems()) {
			const recommendedValidationConfirmed = await confirmDialog.value.openDialog("Recommended values not completed, do you still want to continue?", { type: 'warning', confirmText: 'Cancel', cancelText: 'Continue', closeOnConfirm: true });
			stay.value = recommendedValidationConfirmed;
			
			if (stay.value)  return false;

		}

		loading.value = true;
		const items = [];
		getItems(items);

		let comparisons = undefined;
		for (let i = 0; i < fieldIds.length; i++) {
			const field = fields.value[fieldIds[i]];
			if (field.isComparison) {
				comparisons = (modelValues['_comparison'] || []).map(x => new EstimateComparison({ fieldOptionId: x }));
			}
		}
		const isNextStep = $store.getters['estimator/isNextStep'](workflowId, sectionId);
		let lastCompleteSectionId = !isPartial && isNextStep ? sectionId : undefined;
		await saveEstimateChanges({ workflowId, fieldIds, items, comparisons, lastCompleteSectionId });
		fetchData();
		return true;
	};

	onBeforeRouteUpdate((to, from) => {
		if (to.params.id && to.params.id !== from.params.id) {
			fetchData();
		}
	});
	if (!isPartial) {
		onBeforeRouteLeave(async (to, from) => {
			if (to.path === from.path) return;
			
			if (!(await save()) && confirmDialog.value) {
				if (stay.value) return false;
				const navigationConfirmed = await confirmDialog.value.openDialog(INVALID_FORM_NAVIGATION_MESSAGE, { type: 'warning', confirmText: 'Discard changes', cancelText: 'Fix errors', closeOnConfirm: true });
				// return false to cancel the navigation and stay on the same page
				if (!navigationConfirmed) return false;
			}
		});
	}

	onBeforeUnload(() => meta.value.dirty);

	const { getRecommendedConversion, getAnyRecommendedConversion, } = useRecommended(fields);

	function setRecommendedItem(fieldId, recommended, i) {
		if (i >= 0) {
			if (recommendedItems[fieldId]) {
				recommendedItems[fieldId] = { ...recommendedItems[fieldId], [i]: recommended };
			} else {
				recommendedItems[fieldId] = { [i]: recommended };
			}
			recommendedItems[fieldId][i] = recommended;
		} else {
			recommendedItems[fieldId] = recommended;
		}
	}

	return {
		estimate,
		model,
		modelLookup,
		modelValues,
		meta,
		errors,
		validate,
		setModelValue,
		setModelValues,
		save,
		resetForm,
		loading,
		getRecommendedConversion,
		getAnyRecommendedConversion,
		getItems,
		setRecommendedItem,
		setErrors,
		getNewFieldArrayItem
	};
}

export function useEstimateWindowDetail(itemIdRef, sourceItemIdRef) {
	const sectionId = EstimateSections.windowList;
	const $router = useRouter();
	const $store = useStore();
	const estimate = safeComputed(() => $store.state.estimator.estimate);
	const fields = safeComputed(() => $store.state.estimator.fields);
	const fieldId = '3.20';
	const fieldIds = [fieldId];
	const fieldIdsWithChildren = fields.value['3.20'].descendantIds;
	// const confirmDialog = inject('confirmDialog')
	const workflowId = EstimateWorkflows.windows;
	const loading = ref(false);
	provide('loading', loading);

	const saveEstimateChanges = inject('saveEstimateChanges');
	provide('estimateSectionId', sectionId);

	// validate that step is enabled complete or current
	const steps = $store.getters['estimator/estimateSteps'](workflowId);
	let step = steps.find(x => x.id === sectionId);
	if (!step || !(step.complete || step.current)) {
		step = $store.getters['estimator/getCurrentStep'](workflowId, steps);
		if (step) {
			$router.push(step.url);
		} else {
			$router.push(`/estimator/estimate/${estimate.value.id}`);
		}
	}

	const hasItemId = computed(() => itemIdRef.value !== 0);
	const hasSourceItemId = computed(() => !hasItemId.value && sourceItemIdRef.value !== 0);
	const estimateItemIdRef = computed(() => hasItemId.value ? itemIdRef.value : (hasSourceItemId.value > 0 ? sourceItemIdRef.value : 0));

	const {
		getItems,
		modelValues,
		getData,
		meta,
		model,
		errors,
		setModelValue,
		setModelValues,
		resetForm,
		validate,
	} = useEstimateFields(estimate, fieldIds, false, estimateItemIdRef, {
		onFetchData: [
			(values) => {
				if (hasSourceItemId.value) {
					setModelValueTempItemId_recursive(values.windows, null);
				}
			}
		]
	});

	const initialData = ref(JSON.stringify(modelValues));
	function fetchData() {
		loading.value = true;
		const values = getData();
		initialData.value = JSON.stringify(values);
		resetForm({ values: values });
		loading.value = false;
	}

	const save = async () => {
		if (!(await validate()).valid) {
			$store.dispatch('addMessage', { message: INVALID_FORM_MESSAGE, type: MessageType.warning, autoClose: true });
			return false;
		}
		loading.value = true;
		const originalItemIds = getDescendantIds(itemIdRef.value, estimate.value.itemTreeByParent, new Set());
		const items = estimate.value.items.filter(x => fieldIdsWithChildren.has(x.fieldId) && !originalItemIds.has(x.id));
		getItems(items);
		const isNextStep = $store.getters['estimator/isNextStep'](workflowId, sectionId);
		let lastCompleteSectionId = isNextStep ? sectionId : undefined;
		await saveEstimateChanges({ workflowId, fieldIds, items, lastCompleteSectionId });
		initialData.value = JSON.stringify(modelValues);
		let lastId = itemIdRef.value;
		if (lastId === 0) {
			const candidates = estimate.value.items.filter(x => x.fieldId === fieldId);
			if (candidates.length > 0) {
				lastId = candidates.pop().id;
			}
		}
		$router.replace({ path: `/estimator/estimate/${estimate.value.id}/windows/2/0/${lastId}`, query: {} });
		loading.value = false;
		return true;
	};

	watch([itemIdRef, sourceItemIdRef], fetchData);
	onBeforeRouteUpdate(unsavedGuard);
	onBeforeRouteLeave(unsavedGuard);
	async function unsavedGuard(to, from) {
		if (to.path === from.path) return;
		const oldModel = JSON.parse(initialData.value);
		const newModel = JSON.parse(JSON.stringify(modelValues));
		if (isChanged(newModel, oldModel) && confirmDialog.value) {
			const navigationConfirmed = await confirmDialog.value.openDialog(WINDOW_UNSAVED_CHANGES, { type: 'warning', confirmText: 'Discard changes', cancelText: 'Cancel', closeOnConfirm: true });
			// return false to cancel the navigation and stay on the same page
			if (!navigationConfirmed) return false;
		}
	}

	onBeforeUnload(() => meta.value.dirty);

	const { getRecommendedConversion, getAnyRecommendedConversion, } = useRecommended(fields);

	const { deleteWindow } = useWindowDelete();

	const confirmDialog = inject('confirmDialog');

	async function onDelete() {
		const id = modelValues.windows.length > 0 ? modelValues.windows[0].id : 0;
		if (id <= 0 && id % 2 === 0) { return; } // window not saved
		const message = 'Are you sure you want to delete this window?';
		const confirmed = await confirmDialog.value.openDialog(message, { type: 'warning', confirmText: 'Delete' });
		if (!confirmed) return;

		resetForm();
		$router.replace({ path: `/estimator/estimate/${estimate.value.id}/windows/2/0/${id}`, query: {} });
		await deleteWindow(id);

		confirmDialog.value.closeDialog();
	}

	return {
		estimate,
		model,
		modelValues,
		meta,
		errors,
		validate,
		setModelValue,
		setModelValues,
		save,
		resetForm,
		loading,
		getRecommendedConversion,
		getAnyRecommendedConversion,
		getItems,
		deleteWindow: onDelete,
	};
}

export function useWindowDelete() {
	const $store = useStore();
	const estimate = safeComputed(() => $store.state.estimator.estimate);
	const saveEstimateChanges = inject('saveEstimateChanges');
	const fieldIdsWithChildren = fieldInfo['3.20'].descendantIds;
	async function deleteWindow(id) {
		const originalItemIds = getDescendantIds(id, estimate.value.itemTreeByParent, new Set());
		const items = estimate.value.items.filter(x => fieldIdsWithChildren.has(x.fieldId) && !originalItemIds.has(x.id));
		await saveEstimateChanges({ workflow: EstimateWorkflows.windows, fieldIds: ['3.20'], items });
	}
	return { deleteWindow };
}

export function useEstimateFieldsPage(workflowId, fieldIds, includedItemsRef = {}) {
	const $store = useStore();
	const estimate = ref((function () {
		const source = $store.state.estimator.estimate;
		if (!source) return null;
		let e = new Estimate();
		e.items = source.items.filter(x => x.workflowId === workflowId);
		const workflowKey = EstimateWorkflows.map[workflowId].key;
		e.workflowSummaries[workflowKey] = source.workflowSummaries[workflowKey];
		return e.clone();
	})());
	const loading = ref(false);
	provide('loading', loading);
	provide('estimateWorkflowId', workflowId);

	const {
		getItems,
		modelValues,
		getData,
		meta,
		validate,
		model,
		errors,
		setModelValue,
		setModelValues,
		resetForm,
		modelLookup,
	} = useEstimateFields(estimate, fieldIds, false);

	async function updateModelValues() {
		if (!estimate.value) { return; }
		const items = [];
		getItems(items);

		const e = estimate.value.clone();
		e.updateItems(items, fieldIds, $store.state.estimator.fields);
		estimate.value = e;

		const values = getData();
		resetForm({ values: values });
	}

	onBeforeUnload(() => meta.value.dirty);

	return {
		updateModelValues,
		estimate,
		model,
		modelValues,
		modelLookup,
		meta,
		validate,
		errors,
		setModelValue,
		setModelValues,
		resetForm,
	};
}

export function useEstimateFieldsValues(fieldIds) {
	const $store = useStore();
	const estimate = safeComputed(() => $store.state.estimator.estimate);

	const {
		modelValues
	} = useEstimateFields(estimate, fieldIds, false);

	return {
		modelValues
	};
}

export function useEstimateDiscountSection(sectionId) {
	const $store = useStore();
	const estimate = safeComputed(() => $store.state.estimator.estimate);
	const fields = safeComputed(() => $store.state.estimator.fields);
	const fieldIds = fieldMapping[sectionId];
	const saveEstimateDiscount = inject('saveEstimateDiscount');

	const workflowId = EstimateSections.getWorkflowId(sectionId);
	const workflowKey = EstimateWorkflows.getKey(workflowId);

	let schema = {};
	let model = {};

	const field = fields.value[fieldIds[0]];
	schema[field.property] = yupObject({ quantity: yupNumber().typeError('${path} is required.').required().min(field.min).max(field.max).step(field.min, field.step).label(field.name) });
	model[field.property] = { field };
	model = reactive(model);

	function getData() {
		const values = {};
		const value = values[field.property] = { quantity: 0 };
		const summary = estimate.value.workflowSummaries[workflowKey];
		if (summary) {
			value.quantity = Math.round(summary.discountPercent * 100);
		} else {
			value.quantity = 0;
		}
		return values;
	}
	const { resetForm, validate, values: modelValues, setFieldValue: setModelValue } = useForm({ validationSchema: yupObject(schema), initialValues: getData() });
	const fetchData = () => {
		const values = getData();
		resetForm({ values });
	};

	const save = async () => {
		if (!(await validate()).valid) {
			$store.dispatch('addMessage', { message: INVALID_FORM_MESSAGE, type: MessageType.warning, autoClose: true });
			return false;
		}
		const discountPercent = (modelValues[field.property].quantity || 0) / 100;
		await saveEstimateDiscount({ workflowId, discountPercent });
		fetchData();
		return true;
	};

	onBeforeRouteUpdate((to, from) => {
		if (to.params.id && to.params.id !== from.params.id) {
			fetchData();
		}
	});

	return {
		estimate,
		model,
		save,
		resetForm,
		setModelValue: setModelValue,
		modelValues: modelValues,
	};
}
